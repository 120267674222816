<template>
  <div class="container">
    <div class="btn" @click="toApply">Create A New Entry</div>
    <el-empty
      v-if="!datas.length"
      description="No Entries Yet. You need to create a new entry first."
      :image-size="220"
    ></el-empty>
    <div
      v-for="(item, index) in datas"
      :key="index"
      class="item"
      @click="toApplication(item)"
    >
      <div style="width: 190px; height: 190px;position: relative;">
        <img v-if="!item.entry_info.cover_pic" style="width: 190px; height: 190px;" src="../../assets/pic.png" />
        <img v-if="item.entry_info.cover_pic" style="width: 190px; height: 190px;" :src="item.entry_info.cover_pic" />
        <!-- <div
          class=""
          style="padding: 8px 12px; border-radius: 0 15px 0 15px; position: absolute; top: 0; right: -1px; background: #F65D4D;"
          v-if="item.application_status === '已缴费'"
        >
          {{ 'Paid' }}
        </div>
        <div
          class=""
          style="padding: 8px 12px; border-radius: 0 15px 0 15px; position: absolute; top: 0; right: -1px; background: #FFB414;"
          v-else
        >
          {{ 'Pending payment' }}
        </div> -->
      </div>
      <div class="main">
        <div class="name">
          {{ item.entry_info.name }}
        </div>
        <div class="id">

          <span v-if="item.application_status === '已缴费'" style="border: 1px solid #FFB414;color: #FFB414; font-size: 20px;border-radius: 17px; height:34px;line-height: 34px; text-align: center; padding: 0 12px;color: #FFB414;">
            Paid
          </span>

          <span v-else-if="item.application_status === '已取消'" style="border: 1px solid #999999;color: #999999; font-size: 20px;border-radius: 17px; height:34px;line-height: 34px; text-align: center; padding: 0 12px;color: #999999;">
            Cancel
          </span>
          <span v-else style="border: 1px solid #F65D4D;color: #F65D4D; font-size: 20px;border-radius: 17px; height:34px;line-height: 34px; text-align: center; padding: 0 12px;color: #F65D4D;">
            Pending payment
          </span>

          <span style="margin-left: 20px;border: 1px solid #999999;color: #999999; font-size: 20px;border-radius: 17px; height:34px;line-height: 34px; text-align: center; padding: 0 12px;">
            ID:{{ item.entry_info.entry_code }}
          </span>
          <span style="margin-left: 20px;border: 1px solid #17396D; font-size: 20px;border-radius: 17px; height:34px;line-height: 34px; text-align: center;color: #00255E;padding: 0 12px;">
            {{ item.entry_info.category }}
          </span>
          <span
            style="margin-left: 20px;border: 1px solid #17396D; font-size: 20px;border-radius: 17px;height:34px;line-height: 34px; text-align: center; color: #00255E;padding: 0 12px;">
            {{ item.application_project }}
          </span>
        </div>
        <div class="intro">{{ item.entry_info.intro }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { getStudentApplications } from '../../api/index'
import { getUserId, getProject } from '../../utils/store'
export default {
  data () {
    return {
      datas: []
    }
  },
  mounted () {
    this.fetchData()
  },
  methods: {
    fetchData () {
      getStudentApplications(getUserId(), getProject(), 'MR').then((res) => {
        if (res.data.code === 0) {
          this.datas = res.data.data
        }
      })
    },
    toApply () {
      this.$router.push({
        path: '/apply',
        query: {
          program: 'MR'
        }
      })
    },
    toApplication (item) {
      this.$router.push({
        path: '/application',
        query: {
          id: item._id.$id
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding-top: 11%;
  padding-left: 4%;
  padding-right: 4%;
  overflow-y: scroll;
  .btn {
    background: #0e4890;
    width: 170px;
    height: 34px;
    border-radius: 20px;
    line-height: 34px;
    text-align: center;
    color: #fff;
    position: absolute;
    top: 11%;
    right: 4%;
    cursor: pointer;
  }
  .item {
    display: flex;
    /*height: 200px;*/
    margin-bottom: 30px;
    cursor: pointer;
    img {
      display: block;
      /*height: 200px;
      width: 200px;*/
      border: 1px dotted #0e4890;
      border-radius: 15px;
      cursor: pointer;
    }
    .main {
      padding: 4px;
      margin-left: 16px;
      height: 100%;
      .name {
        color: #333333;
        font-weight: bold;
        /*font-size: 20px;*/
        font-size: 32px;
        text-overflow: ellipsis;//超出文本设置为...
        display:-webkit-box;//将div1转换为盒子模型
        -webkit-line-clamp: 2;
        overflow: hidden;//超出隐藏
        -webkit-box-orient: vertical;//从顶部向底部垂直布置子元素
        cursor: pointer;
        padding-bottom: 5px;
      }
      .id {
        margin-top: 10px;
        color: #949494;
      }
      .tags {
        margin-top: 10px;
      }
      .tag {
        border: 1.4px solid #17396d;
        color: #17396d;
        padding: 0 8px;
        border-radius: 12px;
        margin-right: 8px;
        font-size: 14px;
      }
      .intro {
        margin-top: 16px;
        color: #949494;
        // height: 40px;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;//超出文本设置为...
        display:-webkit-box;//将div1转换为盒子模型
        -webkit-line-clamp: 2;
        overflow: hidden;//超出隐藏
        -webkit-box-orient: vertical;//从顶部向底部垂直布置子元素
      }
    }
  }
}
</style>
